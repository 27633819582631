/* You can add global styles to this file, and also import other style files */


@import "./theme/three-dots.css";
@import "./theme/base-css.scss";
body {
    height: 100%;
    font-family: "Poppins", sans-serif;
    margin: 0;
}
